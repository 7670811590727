<template>
  <div class="demand_container">
    <div v-if="info.length > 0" class="img" @click="handleJump(info[0].jump)">
      <img :src="info[0].image.url" alt="" />
    </div>
    <div class="content flex_row">
      <div v-for="item in list" :key="item.id" class="main_wrap">
        <div style="width: 590px; height: 258px">
          <img :src="item.logo" alt="" />
        </div>
        <div class="bgbox">
          <div class="flex_row_aic">
            <p class="title">{{ item.title }}</p>
            <p>
              (可购数量：<span>{{ item.limit_num }}</span> 套)
            </p>
          </div>

          <div>
            <p class="time">活动时间：{{ item.time_str }}</p>
            <div>倒计时：{{ item.djs }}</div>
          </div>
        </div>
        <div class="main">
          <div class="flex_row goods_box">
            <div v-for="sub in item.goods" :key="sub.id" class="item">
              <img class="url" style="width: 125px; height: 125px" :src="sub.image.url" alt="" />
              <p class="shenglue_1 c3 bold">{{ sub.title }}</p>
              <p class="shenglue_1 c3 f12">{{ sub.sku }}</p>
              <p class="shenglue_1 c9 f12">{{ sub.spscqy }}</p>
              <div class="flex_row_aic">
                <p class="red mt_10 bold" v-if="item.is_cart == '101'">￥{{ sub.price }}</p>
                <p class="red mt_10 bold" v-else>￥--</p>
                <p class="f12 mt_10 c9 huaxianjia" v-if="item.is_cart == '101'">￥{{ sub.price1 }}</p>
              </div>
              <div class="biao">
                <p>{{ sub.num }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="flex_row_aic">
            <p class="p1"><span class="f14">套餐价：</span>{{ item.price }}</p>
            <p class="p2" v-if="item.is_cart == '101'">{{ item.price1 }}</p>
          </div>
          <div class="flex_row_aic" v-if="item.is_cart == '101'">
            <p class="red bold" v-if="item.discount > 0">省：￥{{ item.discount }}</p>
            <div v-if="item.stock" class="btn flex_c_c" @click="handleBuy(item.id)">立即购买</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DeepClone } from "@/utils/tool.js";
export default {
  data() {
    return { list: [], info: [] };
  },
  created() {
    this.$api("zone.getPackage").then((res) => {
      this.list = res.data;
      let arr = DeepClone(this.list);
      arr.map((obj) => {
        this.$set(obj, "djs", this.InitTime(obj.time));
      });
      this.list = arr;
    });
    // 获取广告
    this.$api("zone.getAdv", { mid: localStorage.getItem("mid") }).then((res) => {
      this.info = res.data;
    });
  },
  mounted() {
    setInterval(() => {
      for (var key in this.list) {
        var rightTime = Math.floor((this.list[key]["time"] * 1000 - Date.now()) / 1000);
        if (rightTime > 0) {
          var dd = Math.floor(rightTime / 60 / 60 / 24);
          var hh = Math.floor((rightTime / 60 / 60) % 24);
          var mm = Math.floor((rightTime / 60) % 60);
          var ss = Math.floor(rightTime % 60);
        }
        this.list[key]["djs"] = dd + "天" + hh + "小时" + mm + "分" + ss + "秒";
      }
    }, 1000);
  },
  methods: {
    InitTime(endtime) {
      var dd,
        hh,
        mm,
        ss = null;
      var time = Math.floor((endtime * 1000 - Date.now()) / 1000);
      if (time <= 0) {
        return "结束";
      } else {
        dd = Math.floor(time / 60 / 60 / 24);
        hh = Math.floor((time / 60 / 60) % 24);
        mm = Math.floor((time / 60) % 60);
        ss = Math.floor(time % 60);
        var str = dd + "天" + hh + "小时" + mm + "分" + ss + "秒";
        return str;
      }
    },

    handleBuy(id) {
      this.$api("goods.addCart", { id, number: 1 }).then(() => {
        this.$store.dispatch("getBadge");
        this.$message.success("加入购物车成功");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.img {
  // width: 100%;
  width: 1200px;
  height: 375px;
  margin: 0 auto;
}
.red {
  color: @priceRed;
}
.content {
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 15px;
  .bgbox {
    width: 590px;
    height: 60px;
    background: linear-gradient(90deg, #0abc90 0%, #0abc64 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
    color: #fff;
    .title {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .main_wrap {
    margin-top: 15px;
    width: 590px;
  }
  .main {
    background-color: #fff;
    padding: 10px;
    padding-bottom: 0;
    box-sizing: border-box;
    overflow-x: scroll;
    position: relative;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(40, 44, 52, 0.1);
      border: 3px solid transparent;
      border-radius: 7px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(40, 44, 52, 0.1);
    }
    .title_wrap {
      color: #333;
      padding: 10px;
      align-items: center;
      .time {
        font-size: 14px;
      }
    }
    .goods_box {
      padding-top: 20px;
    }
    .item {
      flex-shrink: 0;
      width: 123px;
      margin-right: 20px;
      margin-left: 3px;
      position: relative;
      .url {
        border: 1px solid #eee;
        margin-bottom: 8px;
      }
      .huaxianjia {
        margin-left: 8px;
        text-decoration: line-through;
      }
      .biao {
        width: 26px;
        height: 25px;
        position: absolute;
        right: -15px;
        top: 110px;
        font-size: 20px;
        background-image: url("../../assets/img/index/biao.png");
        p {
          font-weight: bold;
          color: #ffffff;
          font-size: 14px;
          line-height: 26px;
          text-align: center;
        }
      }
    }
  }
  .bottom {
    padding: 15px 20px;
    border-radius: 0 0 16px 16px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .p1 {
      font-size: 22px;
      font-weight: bold;
      color: @priceRed;
      margin-right: 10px;
    }
    .p2 {
      color: #999;
      font-size: 16px;
      text-decoration: line-through;
    }
    .btn {
      width: 100px;
      height: 36px;
      background: #ff4c4c;
      border-radius: 2px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      margin-left: 25px;
      cursor: pointer;
    }
  }
}
</style>
