<template>
  <div class="five" :style="{ backgroundImage: `url(${data.floor[0].bj_img})` }">
    <div class="tile_wrap">
      <div class="left">
        <img class="img" :src="data.floor[0].icon" alt="" />
      </div>
      <!-- <div class="right c_p" @click="handleJump(data.floor[0].jump)"> -->
      <div class="right" @click="handleMore(data.floor[0])">
        <span style="font-size: 16px; cursor: pointer; font-weight: bold; color: #f14948">更多</span>
        <div class="more">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="left" @click="handleJump(data.floor[0].adv_jump)">
        <img :src="data.floor[0].adv.image" alt="" />
      </div>
      <div class="right">
        <div class="item" v-for="item in data.floor[0].goodsArr" :key="item.id" @click="handleDetail(item.id)">
          <div class="img">
            <img :src="item.image.url" alt="" />
          </div>
          <p class="pr">
            ￥{{ item.price }}
            <span v-if="item.price1" style="text-decoration: line-through; color: rgb(116, 113, 113); font-size: 12px">{{ item.price1 }}</span>
          </p>
          <!-- <p class="title-qhj">{{ item.price_qhj }}</p> -->
          <p v-if="item.price_qhj != ''" class="title-qhj">{{ item.price_qhj }}</p>
          <p v-else style="color: #ffffff; font-size: 13px; font-weight: bold">券后价</p>
          <!-- <tagcom :tags="item.tags"></tagcom> -->
          <p class="til shenglue_1">{{ item.title }}</p>
          <p class="shenglue_1 f12">{{ item.specification }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleJump } from "@/utils";
// import tagcom from "@/components/tag.vue";
export default {
  // components: {
  //   tagcom,
  // },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      handleJump,
    };
  },
  methods: {
    handleDetail(id) {
      const { href } = this.$router.resolve({
        path: "/index/goodsDetail",
        query: { id },
      });
      window.open(href, "_blank");
    },
    handleMore(item) {
      console.log("首页---", item);
      // this.$store.commit("CHANGE_PAGENAME", ["首页", item.title]);
      this.$store.commit("CHANGE_PAGENAME", []);
      if (item.jump.url == "/index/allGoods") {
        this.$store.commit("CHANGE_TAB", 1);
        localStorage.setItem("mid", 21);
      }
      handleJump(item.jump);
    },
    // jumpFn(item) {
    //   handleJump(item);
    // },
  },
};
</script>

<style lang="less" scoped>
.five {
  width: 100%;
  height: 598px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 20px;
  background-size: contain;
  .tile_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    .left {
      display: flex;
      align-items: center;
      .img {
        width: 120px !important;
        height: 29px !important;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .more {
        width: 9px;
        height: 16px;
        margin-left: 10px;
      }
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .main {
    padding: 20px;
    padding-top: 0;
    display: flex;
    .left {
      width: 365px;
      height: 510px;
      border-radius: 4px;
      cursor: pointer;
    }
    .right {
      display: flex;
      // justify-content: space-between;
      align-content: space-between;
      flex-wrap: wrap;
      flex: 1;
      margin-left: 9px;
      .item {
        width: 188px;
        height: 249px;
        margin-right: 4px;
        border: 1px solid #dddddd;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        &:hover {
          box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.8);
          border: 1px solid #fff;
        }
        .img {
          width: 150px;
          height: 150px;
          background: #eeeeee;
          border-radius: 4px;
        }
        .til {
          font-weight: bold;
          color: #333333;
          margin: 4px 0;
        }
        .pr {
          font-size: 16px;
          font-weight: bold;
          margin-top: 4px;
          color: @priceRed;
        }
        .title-qhj {
          color: rgb(255, 76, 76);
          font-size: 13px;
          // margin-left: 10px;
          font-weight: bold;
          // position: absolute;
          // right: 10px;
        }
      }
    }
  }
}
</style>
