<template>
  <div class="goodsdetail_container">
    <div class="content">
      <div class="top">
        <div class="left">
          <div>
            <div class="img_box">
              <!-- 换购-红色皮肤 -->
              <div v-if="hg_flag" class="red-line-box"></div>
              <div v-if="hg_flag" class="hg-info">
                <div class="hg-info-box">
                  <div class="hg-title">换购价</div>
                  <div v-if="info.str_btn1 && info.show_cart == 101" class="hg-price">￥{{ info.avg_price }}</div>
                  <div v-else class="hg-price">￥--</div>
                  <div class="hg-dsesc">{{ info.show_memo }}</div>
                </div>
              </div>
              <!-- 换购-红色皮肤 -->
              <div v-if="tj_flag" class="yellow-line-box"></div>
              <div v-if="tj_flag" class="tj-info">
                <div class="hg-info-box">
                  <div class="hg-title">活动价</div>
                  <div class="hg-price">￥{{ info.price }}</div>
                  <!-- <div class="hg-dsesc">{{ info.show_memo }}</div> -->
                </div>
              </div>
              <img :src="bigImg" alt="" />
            </div>
            <div v-show="topShow" class="img_top" :style="topStyle"></div>
            <div class="maskTop" @mouseenter="enterHandler" @mousemove="moveHandler" @mouseout="outHandler"></div>
            <div v-show="rShow" class="img_right_big">
              <img :style="r_img" class="rightImg" :src="bigImg" alt="" />
            </div>
          </div>

          <div class="thumb_wrap">
            <div class="thumb_box">
              <div class="item" v-for="item in info.thumb" :key="item.url" @mouseenter="mouseenter(item)">
                <img :src="item.url" alt="" />
              </div>
            </div>
          </div>
          <div class="tip">温馨提示：部分商品包装更换频繁，如货品与图片不一致， 请以收到的商品实物为准</div>
        </div>
        <div class="right">
          <div class="title">{{ info.title }}</div>
          <div class="pr_box">
            会 员 价：<span class="price">
              ￥<span>{{ info.price }}</span> <span class="huaxian" v-if="info.price1">{{ info.price1 }}</span>
            </span>
            <span v-if="info.price_qhj != ''" style="color: #ff4c4c; font-size: 13px; margin-left: 10px">{{ info.price_qhj }}</span>
            <!-- <span v-if="info.avg_price != ''" style="color: #ff4c4c; font-size: 16px; margin-left: 10px">换购价约：{{ info.avg_price }}</span> -->
            <span v-if="info.avg_price" style="color: #ff4c4c; margin-left: 184px">换 购 价 ≈</span>
            <span v-if="info.avg_price" class="price">
              ￥<span>{{ info.avg_price }}</span>
            </span>
          </div>
          <!-- <div class="flex_row mb_10" v-for="item in info.tags" :key="item.title">
            <div :style="`color: #${item.color}; border: 1px solid #${item.color};`" class="tag mr_10 p_title">
              {{ item.title }}  
            </div>
          </div> -->
          <div class="flex_row mb_10" v-if="info.activity && info.activity.length">
            <p class="mr_20 p_title">全场满减</p>
            <div v-for="item in info.activity" :key="item.id" class="mr_10">
              {{ item.content }}
            </div>
          </div>
          <div class="flex_row mb_10" v-if="info.lqyhj && info.lqyhj.length">
            <p class="mr_20 p_title">优惠券</p>
            <div v-for="item in info.lqyhj" :key="item.id" class="mr_10">满{{ item.zdje_str }}优惠{{ item.hbje_str }}</div>
          </div>
          <div class="tags">
            <div class="flex_row mb_10" v-for="item in info.activity_tag" :key="item.title">
              <p class="mr_10 p_title" :style="`color: #${item.color}; border: 1px solid #${item.color};`">
                {{ item.title }}
              </p>
              <div class="mr_10">
                {{ item.content }}
              </div>

              <!-- <div v-if="info.activity_jssj" >距离活动结束时间：{{time.d}}天{{time.h}}时{{time.m}}分{{time.s}}秒</div> -->
              <!-- <div v-if="item.jssj">距离活动结束时间：{{ time.d }}天{{ time.h }}时{{ time.m }}分{{ time.s }}秒</div> -->
            </div>
          </div>
          <div class="flex_row mb_10" v-if="show_memo != ''">
            <div class="mr_10" style="color: red; font-size: 16px">
              {{ info.show_memo }}
            </div>
          </div>
          <!-- <div class="flex_row mb_10" v-if="info.activity_tag && info.activity_tag.title">
              <p class="mr_20 p_title" :style="`color: #${info.activity_tag.color}; border: 1px solid #${info.activity_tag.color};`">
                {{ info.activity_tag.title }}
              </p>
              <div class="mr_10">
                {{ info.activity_tag.content }}
              </div>
            </div> -->
          <div class="detail">
            <div>
              库存数量：<span>{{ info.stock_str }}</span>
            </div>
            <div class="flex">
              <div>
                商品规格：<span>{{ info.specification }}</span>
              </div>
              <div>
                批准文号：<span>{{ info.approval_number }}</span>
              </div>
            </div>
            <div class="flex">
              <div>
                件 装 量 ：<span>{{ info.jzl }}</span>
              </div>
              <div>
                中 包 装 ：<span>{{ info.zbz }}</span>
              </div>
            </div>
            <div class="flex">
              <div>
                商品编码：<span>{{ info.erpcode }}</span>
              </div>
              <div class="flex-row-item">
                生产企业：
                <div>{{ info.manufacturer }}</div>
              </div>
            </div>

            <div class="flex">
              <div>
                生产日期：<span>{{ info.scrq }}</span>
              </div>
              <div>
                有 效 期 ：<span>{{ info.validity_date }}</span>
                <!-- <span style="color: red">{{ info.stock_str }}</span> -->
              </div>
            </div>
            <div class="flex">
              <div>
                国 家 码 ：<span>{{ info.gjm }}</span>
              </div>
              <div>
                是否采集：<span>{{ info.is_cj }}</span>
              </div>
            </div>
          </div>
          <div class="pr_box">
            购买数量：
            <div class="number_box">
              <div class="numleft c_p" @click="handleMinus">
                <i class="el-icon-minus"></i>
              </div>
              <!-- <div class="num">{{ info.cart_num }}</div> -->
              <div class="num">
                <el-input v-model="info.number" @blur="blur(info)"></el-input>
              </div>
              <div class="numright c3 c_p" @click="handlePlus">
                <i class="el-icon-plus"></i>
              </div>
            </div>
          </div>
          <div class="flex_row">
            <div class="btn mr_20" style="background: #dddddd; color: #999" v-if="info.show_cart == 102">{{ info.str_btn1 }}</div>
            <div class="btn mr_20" @click="addToCart" v-if="info.str_btn1 && info.show_cart == 101">{{ info.str_btn1 }}</div>
            <!-- <div  class="btn" @click="handleBuy" v-if="info.str_btn2 && info.show_cart==101">{{ info.str_btn2 }}</div> -->
            <div class="btn" @click="collectClick">{{ this.info.is_collect == 102 ? "加入收藏" : "取消收藏" }}</div>
          </div>
        </div>
      </div>
      <div class="main_wrap">
        <div class="hot">
          <div class="title flex_c_c">本类热销商品</div>
          <div class="goods_wrap">
            <div class="flex_column" v-for="item in hotGoods" :key="item.id">
              <div class="goodsimg">
                <img :src="item.image.url" alt="" />
              </div>
              <div class="c3">{{ item.title }}</div>
              <div class="c9">
                成交量：<span>{{ item.sales }}</span>
              </div>
              <div class="c9">
                会员价：<span>{{ item.price }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="main">
          <div class="tab">
            <div class="item" :class="current === i ? 'active' : ''" @click="current = i" v-for="(item, i) in tab" :key="item.name">
              {{ item.name }}
            </div>
          </div>
          <div class="cont">
            <div v-if="current == 0" v-html="info.intro"></div>
            <div v-if="current == 1" v-html="info.distribution_instructions" />
            <div v-if="current == 2" v-html="info.security_privacy"></div>
            <div v-if="current == 3" v-html="info.honors"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: 0,
      tab: [{ name: "说明书" }, { name: "配货说明" }, { name: "安全隐私" }, { name: "资质荣誉" }], //
      info: {},
      bigImg: "",
      hotGoods: [],
      hg_flag: false,
      tj_flag: false,
      topStyle: { transform: "" },
      r_img: {},
      topShow: false,
      rShow: false,
      goods_id: "",
      setInt: [],
      time: {
        d: 0,
        h: 0,
        m: 0,
        s: 0,
      },
    };
  },
  created() {
    // document.title = '商品详情';
    //解析query value值
    let param = this.$route.query;

    if (this.$route.query.id > 0) {
      this.goods_id = this.$route.query.id;
      this.getdata();
    } else if (param != "") {
      let queryVal = param.value;
      queryVal = queryVal.replace("?", "");
      let queryValArr = queryVal.split("&");
      queryValArr.forEach((item) => {
        let arr = item.split("=");
        if (arr[0] == "id") {
          this.goods_id = arr[1];
          this.getdata();
        } else if (arr[0] == "title") {
          document.title = arr[1];
        }
      });
    } else {
      this.$message.error("未上传商品标识");
    }
  },
  methods: {
    collectClick() {
      if (this.info.is_collect == 102) {
        this.$api("goods.addCollect", { id: this.info.id }).then(() => {
          this.$message.success("收藏成功");
          this.info.is_collect = 101;
        });
      } else {
        this.$api("account.delCollect", { id: this.info.id }).then(() => {
          this.$message.success("取消收藏成功");
          this.info.is_collect = 102;
        });
      }
    },

    formatDateToYYYYMMDDHHMMSS(dateStr) {
      const date = new Date(dateStr);
      const dateL = Number(Date.parse(date) - Date.parse(new Date())) / 1000;
      // console.log(dateL)
      this.time.d = Math.floor(dateL / 24 / 60 / 60);
      this.time.h = Math.floor((dateL - 24 * 60 * 60 * this.time.d) / 60 / 60);
      this.time.m = Math.floor((dateL - 24 * 60 * 60 * this.time.d - 60 * 60 * this.time.h) / 60);
      this.time.s = Math.floor(dateL - 24 * 60 * 60 * this.time.d - 60 * 60 * this.time.h - 60 * this.time.m);
    },

    getdata() {
      this.$api("goods.getGoodsDel", { id: this.goods_id }).then((res) => {
        this.info = res.data;
        this.bigImg = this.info.image.url;

        this.getHotGoods(res.data.cate_id);
        document.title = res.data.title;
        if (this.info.activity_jssj != "") {
          this.setInt = setInterval(() => {
            this.formatDateToYYYYMMDDHHMMSS(this.info.activity_jssj);
          }, 1000);
        }
        // console.log('结束时间',this.info.activity_jssj)
        if (this.info.tags.length > 0) {
          let is_hg = this.info.tags.filter((v) => {
            return v.title == "换购";
          });
          let is_tj = this.info.tags.filter((v) => {
            return v.title == "特价";
          });
          if (is_hg.length > 0 && is_tj.length == 0) {
            this.hg_flag = true;
          }

          if (is_tj.length > 0) {
            this.tj_flag = true;
          }
        }
      });
    },
    getHotGoods(cate_id) {
      this.$api("goods.getHotGoods", {
        cate_id,
        order_key: 102,
        page: 1,
        page_size: 3,
      }).then((res) => {
        this.hotGoods = res.data;
      });
    },
    mouseenter(item) {
      this.bigImg = item.url;
    },
    addToCart() {
      let p = {
        id: this.info.id,
        number: this.info.number,
      };
      this.$api("goods.addCart", p).then(() => {
        this.$message.success("加入购物车成功");
        this.$store.dispatch("getBadge");
      });
    },
    handleBuy() {
      this.$api("cart.submitCart", { goods: [this.info] }).then((res) => {
        console.log(res);
        this.$router.push({
          name: "CartCheck",
          params: { id: res.data.id },
        });
      });
    },
    handleMinus() {
      let { show_cart, can_buy, can_buy_str, step, add_sl, number } = this.info;

      if (show_cart == 102) return;
      if (can_buy == 102) {
        this.$message.error(can_buy_str); //101可购买 102禁止购买
        return;
      }

      if (Number(step) >= Number(number)) {
        this.$message.error("不能再减少了");
        return;
      }

      this.info.number = Number(number) - Number(add_sl);
    },
    handlePlus() {
      let { show_cart, can_buy, can_buy_str, add_sl, number, max_number } = this.info;

      if (show_cart == 102) return;
      if (can_buy == 102) {
        this.$message.error(can_buy_str); //101可购买 102禁止购买
        return;
      }
      if (Number(number) >= Number(max_number)) {
        this.$message.error("已达库存上限");
        return;
      }

      this.info.number = Number(number) + Number(add_sl);
    },
    blur(item) {
      if (item.add_sl != 1) {
        let num = Number(item.number) / Number(item.add_sl);

        if (num % 1 !== 0) {
          this.info.number = Number(item.add_sl) * (parseInt(num) + 1);
        }
      }
      if (Number(item.number) <= Number(item.step)) {
        this.info.number = item.step;
      }
      if (Number(item.number) >= Number(item.max_number)) {
        this.info.number = item.max_number;
      }
    },

    enterHandler() {
      this.topShow = true;
      this.rShow = true;
    },
    moveHandler(event) {
      // 鼠标的坐标位置
      let x = event.offsetX;
      let y = event.offsetY;
      // 层罩的左上角坐标位置，并对其进行限制：无法超出原图区域左上角
      let topX = x - 100 < 0 ? 0 : x - 100;
      let topY = y - 100 < 0 ? 0 : y - 100;
      // 对层罩位置再一次限制，保证层罩只能在原图区域空间内
      if (topX > 150) {
        topX = 150;
      }
      if (topY > 150) {
        topY = 150;
      }
      // 通过 transform 进行移动控制
      this.topStyle.transform = `translate(${topX}px,${topY}px)`;
      this.r_img.transform = `translate(-${2 * topX}px,-${2 * topY}px)`;
    },
    outHandler() {
      this.topShow = false;
      this.rShow = false;
    },
  },
  beforeDestroy() {
    clearInterval(this.setInt);
  },
  destroyed() {
    clearInterval(this.setInt);
  },
};
</script>

<style lang="less" scoped>
.tags {
  width: 100%;
  display: flex;
  // flex-direction: column;
  justify-content: left;
}
.top {
  background-color: #fff;
  padding: 15px;
  margin-top: 15px;
  display: flex;
  .left {
    width: 350px;
    margin-right: 15px;
    float: left;
    position: relative;
    .img_box {
      width: 350px;
      height: 350px;
      border: 1px solid #dddddd;
      border-radius: 4px;
      position: relative;
      .red-line-box {
        width: 342px;
        height: 342px;
        border: 4px solid red;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        border-radius: 0 0 6px 6px;
      }
      .yellow-line-box {
        width: 342px;
        height: 342px;
        border: 4px solid #fcf4d9;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        border-radius: 0 0 6px 6px;
      }
      .hg-info {
        width: 100%;
        height: 69px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: url("../../assets/img/goods/hg01.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 0 0 6px 6px;
        .hg-info-box {
          width: 100%;
          height: 100%;
          left: 0;
          bottom: 0;
          // border-radius: 0 0 6px 6px;
          .hg-title {
            width: 77px;
            height: 28px;
            text-align: center;
            line-height: 28px;
            position: absolute;
            left: 5px;
            bottom: 38px;
            color: red;
            font-size: 18px;
            // background-color: lightblue;
          }
          .hg-price {
            width: 84px;
            height: 40px;
            position: absolute;
            left: 5px;
            bottom: 0px;
            text-align: center;
            line-height: 40px;
            // background-color: #fff;
            color: #fcf4d9;
            font-size: 20px;
            font-weight: bold;
          }
          .hg-dsesc {
            width: 250px;
            height: 46px;
            position: absolute;
            right: 0px;
            bottom: 6px;
            text-align: left;
            color: #fcf4d9;
            font-size: 16px;
            // background-color: lightblue;
            overflow: hidden;
            -webkit-line-clamp: 2;
            // text-overflow: ellipsis;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            word-break: break-word;
          }
        }
      }
      .tj-info {
        width: 100%;
        height: 69px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: url("../../assets/img/goods/hg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 0 0 6px 6px;
        .hg-info-box {
          width: 100%;
          height: 100%;
          left: 0;
          bottom: 0;
          // border-radius: 0 0 6px 6px;
          .hg-title {
            width: 77px;
            height: 28px;
            text-align: center;
            line-height: 28px;
            position: absolute;
            left: 5px;
            bottom: 38px;
            color: red;
            font-size: 18px;
            // background-color: lightblue;
          }
          .hg-price {
            width: 150px;
            height: 40px;
            position: absolute;
            left: 5px;
            bottom: 0px;
            text-align: left;
            line-height: 40px;
            // background-color: #fff;
            color: red;
            font-size: 20px;
            font-weight: bold;
          }
          .hg-dsesc {
            width: 250px;
            height: 46px;
            position: absolute;
            right: 0px;
            bottom: 6px;
            text-align: center;
            color: #fcf4d9;
            font-size: 16px;
            // background-color: lightblue;
            overflow: hidden;
            -webkit-line-clamp: 2;
            // text-overflow: ellipsis;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            word-break: break-word;
          }
        }
      }
    }
    .img_top {
      width: 200px;
      height: 200px;
      background-color: #e8e7e7;
      opacity: 0.4;
      position: absolute;
      top: 0;
      left: 0;
    }
    .maskTop {
      width: 350px;
      height: 350px;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    }
    .img_right_big {
      margin-left: 360px;
      width: 350px;
      height: 350px;
      border: 1px solid #dddddd;
      position: absolute;
      overflow: hidden;
      top: 0;
      background-color: #fff;
      .rightImg {
        display: inline-block;
        width: 700px !important;
        height: 700px !important;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .thumb_wrap {
      width: 350px;
      overflow: hidden;
      .thumb_box {
        display: flex;
        margin-top: 15px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba(40, 44, 52, 0.1);
          border: 3px solid transparent;
          border-radius: 7px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: rgba(40, 44, 52, 0.1);
        }
        .item {
          flex-shrink: 0;
          width: 60px;
          height: 60px;
          border: 1px solid #dddddd;
          border-radius: 2px;
          margin-right: 10px;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    .tip {
      margin-top: 15px;
      font-size: 14px;
      font-weight: 400;
      color: #ff4c4c;
    }
  }
  .right {
    flex: 1;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    .pr_box {
      width: 800px;
      height: 36px;
      background: #f3f3f3;
      font-size: 16px;
      line-height: 36px;
      color: #333333;
      margin: 15px 0;
      padding-left: 15px;
      box-sizing: border-box;
      display: flex;

      align-items: center;
      .price {
        font-size: 14px;
        font-weight: bold;
        color: #ff4c4c;
        span {
          font-size: 18px;
        }
        .huaxian {
          font-size: 14px;
          color: #999;
          font-weight: 400;
          text-decoration: line-through;
        }
      }
    }
    .p_title {
      height: 17px;
      border-radius: 4px;
      border: 1px solid #f04844;
      color: #f04844;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 2px 6px;
    }
    .detail {
      color: #999;
      line-height: 2;
      margin-left: 20px;
      .flex {
        display: flex;
        div:first-child {
          width: 300px;
        }
      }
      span {
        color: #333333;
      }
      .flex-row-item {
        display: flex;
        flex-direction: row;
      }
    }
    .number_box {
      margin-left: 10px;
      background-color: #fff;
      width: 91px;
      height: 26px;
      border: 1px solid #dddddd;
      border-radius: 2px;
      display: flex;
      .numleft {
        color: #ddd;
      }
      .numleft,
      .numright {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .num {
        width: 36px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        ::v-deep .el-input__inner {
          padding: 0 4px;
          height: 26px;
          border: none;
          text-align: center;
        }
      }
    }
    .btn {
      width: 100px;
      height: 36px;
      background: @themeColor;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
.main_wrap {
  margin: 10px 0;
  display: flex;
  .hot {
    width: 200px;
    // height: 580px;
    background: #ffffff;
    border-radius: 4px;
    margin-right: 10px;
    .title {
      height: 40px;
      background: #eeeeee;
      border-radius: 4px 4px 0 0;
      font-size: 16px;
      color: #333333;
    }
    .goods_wrap {
      padding: 10px;
      line-height: 1.8;
      span {
        color: #ff4c4c;
      }
      .flex_column {
        padding-bottom: 10px;
        border-bottom: 1px dashed #ddd;
        margin-bottom: 10px;
      }
      .goodsimg {
        width: 180px;
        height: 160px;
        border: 1px solid #dddddd;
        border-radius: 4px;
        margin-bottom: 10px;
      }
    }
  }
  .main {
    width: 990px;
    min-height: 580px;
    background: #ffffff;
    border-radius: 4px;
    .tab {
      height: 40px;
      background: #eeeeee;
      border-radius: 4px 4px 0px 0px;
      border-bottom: 1px solid @themeColor;
      display: flex;
      line-height: 40px;
      .item {
        width: 100px;
        text-align: center;
        color: #333333;
        font-size: 16px;
        cursor: pointer;
        &.active {
          background-color: @themeColor;
          border-radius: 4px 4px 0px 0px;
          color: #fff;
        }
      }
    }
    .cont {
      padding: 10px;
    }
  }
}
</style>
