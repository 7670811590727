<template>
  <div class="goods_wrap" @click="handleDetail" @mouseenter="show = true" @mouseleave="show = false">
    <!-- 换购的红色边框 -->
    <div v-if="item.show_memo && item.hg_flag" class="border-box"></div>
    <!-- 特价的黄色边框 -->
    <div v-if="item.tj_flag" class="border-box-yellow"></div>
    <div class="img">
      <img :src="item.image.url" alt="" />
      <div class="cuxiao" v-if="item.show_memo && !item.hg_flag && !item.tj_flag">{{ item.show_memo }}</div>
      <!-- 换购效果 -->
      <div v-if="item.show_memo && item.hg_flag" class="cuxiao-style">
        <div class="cx-box">
          <div class="cx-title">换购价</div>
          <div class="cx-price" v-if="item.can_buy == '101'">￥{{ item.avg_price }}</div>
          <div class="cx-price" v-else>￥--</div>
          <div class="cx-desc">{{ item.show_memo }}</div>
        </div>
      </div>
      <!-- 特价效果 -->
      <div v-if="item.tj_flag" class="cuxiao-style-tj">
        <div class="cx-box">
          <div class="cx-title">活动价</div>
          <div class="cx-price">{{ item.price }}</div>
        </div>
      </div>
    </div>
    <div class="goods_con">
      <p class="price">
        {{ item.price }}
        <span v-if="item.price1 != ''" style="text-decoration: line-through; color: rgb(116, 113, 113); font-size: 12px">{{ item.price1 }}</span>
        <!-- <span v-if="item.price_qhj != ''" style="color: #ff4c4c; font-size: 13px; margin-left: 10px; position: absolute; right: 10px">{{ item.price_qhj }}</span> -->
      </p>
      <span v-if="item.price_qhj != ''" style="color: #ff4c4c; font-size: 13px; font-weight: bold">{{ item.price_qhj }}</span>
      <span v-else style="color: #ffffff; font-size: 13px; font-weight: bold">券后价：</span>
      <!-- <span v-if="item.price1 != ''" style="text-decoration: line-through; color: rgb(116, 113, 113); font-size: 12px">{{ item.price1 }}</span> -->
      <p class="title shenglue_1">{{ item.title }}</p>
      <div></div>
      <tagcom :tags="item.tags"></tagcom>
      <div class="main">
        <p class="shenglue_1">{{ item.manufacturer }}</p>
        <p class="shenglue_1">规格：{{ item.specification }}</p>
        <div class="df_box">
          <p v-if="item.is_jxq == 101">效期：{{ item.validity_date }}</p>
          <p v-if="item.is_jxq == 102" style="color: red">效期：{{ item.validity_date }}</p>
          <p>件装量：{{ item.jzl }}</p>
        </div>
        <div class="df_box">
          <p>单位：{{ item.bzdw }}</p>
          <p>中包装：{{ item.add_sl }}</p>
        </div>
        <div class="df_box">
          <p>库存：{{ item.kc_str }}</p>
          <p>采集：{{ item.is_cj }}</p>
        </div>

        <div class="df_box">
          <!-- ：{{item.gjm}} -->
          <el-tooltip class="item" effect="light" :content="customContent" placement="top-start">
            <p @click.stop="copyText(item.gjm)" style="line-height: 20px; padding: 0px 10px; border: 1px solid rgb(12, 185, 95); border-radius: 5px; color: rgb(12, 185, 95)">医保码</p>
            <div slot="content" style="background-color: #ffffff">
              <div style="color: #15b9a9; width: 100%; height: 100%">
                <span style="padding: 3px 5px; color: #15b9a9; border: 1px solid #15b9a9; margin-right: 3px" @click.stop="copyText(item.gjm)">复制</span>
                <span>{{ item.gjm }}</span>
              </div>
            </div>
          </el-tooltip>

          <p v-if="item.yblb != ''">医保类型：{{ item.yblb }}</p>
        </div>
      </div>
      <div class="df_box" style="margin-top: 10px" @click.stop>
        <div class="number_box">
          <div class="left" @click.stop="handleMinus">
            <i class="el-icon-minus"></i>
          </div>
          <!-- <div class="num">{{ item.cart_number }}</div> -->
          <div class="num">
            <el-input v-model="item.cart_number" @blur="blur(item)"></el-input>
          </div>
          <div class="right c3" @click.stop="handlePlus">
            <i class="el-icon-plus"></i>
          </div>
        </div>
        <div class="add" :class="[item.show_cart == 101 ? '' : 'gray']" @click.stop="addToCart">加入购物车</div>
      </div>
      <div class="tag_xie">
        <!-- <img src="@/assets/img/goods/tj.png" alt="" /> -->
        <!-- <img src="@/assets/img/goods/jxq.png" alt="" /> -->
      </div>
      <div class="sku" v-if="item.kc_str == '无库存'">
        <img src="@/assets/img/goods/kcbz.png" alt="" />
      </div>
      <div class="kegou" v-if="item.show_red">
        <img src="@/assets/img/goods/bg.png" alt="" />
        <p>{{ item.show_red }}</p>
      </div>
      <div class="collect" v-if="show" @click.stop="collectClick">
        <img v-if="item.is_collect == 102" src="@/assets/img/goods/coll.png" alt="" />
        <img v-if="item.is_collect == 101" src="@/assets/img/goods/iscoll.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import tagcom from "@/components/tag.vue";
export default {
  components: {
    tagcom,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    console.log("商品数据----", this.item);
    if (this.item.tags.length > 0) {
      let hg_str = this.item.tags.filter((v) => {
        return v.title == "换购";
      });
      let tj_str = this.item.tags.filter((v) => {
        return v.title == "特价";
      });
      if (hg_str.length > 0 && tj_str.length == 0) {
        this.item.hg_flag = true;
      }
      if (tj_str.length > 0) {
        this.item.tj_flag = true;
      }
    }
  },
  methods: {
    async copyText(val) {
      try {
        await navigator.clipboard.writeText(val);
        this.$message.success("已复制");
      } catch (err) {
        console.error("复制失败", err);
      }
    },
    handleDetail() {
      const { href } = this.$router.resolve({
        path: "/index/goodsDetail",
        query: { id: this.item.id },
      });
      window.open(href, "_blank");
    },
    addToCart() {
      if (this.item.show_cart != 101) return;
      let p = {
        id: this.item.id,
        number: this.item.cart_number,
      };
      this.$api("goods.addCart", p).then(() => {
        this.$message.success("加入购物车成功");
        this.$store.dispatch("getBadge");
      });
    },
    collectClick() {
      if (this.item.is_collect == 102) {
        this.$api("goods.addCollect", { id: this.item.id }).then(() => {
          this.$message.success("收藏成功");
          this.item.is_collect = 101;
        });
      } else {
        this.$api("account.delCollect", { id: this.item.id }).then(() => {
          this.$message.success("取消收藏成功");
          this.item.is_collect = 102;
        });
      }
    },
    handleMinus() {
      let { show_cart, can_buy, can_buy_str, step, add_sl, cart_number } = this.item;

      if (show_cart == 102) return;
      if (can_buy == 102) {
        this.$message.error(can_buy_str); //101可购买 102禁止购买
        return;
      }

      if (Number(step) >= Number(cart_number)) {
        this.$message.error("不能再减少了");
        return;
      }

      this.item.cart_number = Number(cart_number) - Number(add_sl);
    },
    handlePlus() {
      let { show_cart, can_buy, can_buy_str, add_sl, cart_number, max_number } = this.item;

      if (show_cart == 102) return;
      if (can_buy == 102) {
        this.$message.error(can_buy_str); //101可购买 102禁止购买
        return;
      }
      if (Number(cart_number) >= Number(max_number)) {
        this.$message.error("已达库存上限");
        return;
      }

      this.item.cart_number = Number(cart_number) + Number(add_sl);
    },
    blur(item) {
      if (item.add_sl != 1) {
        let num = Number(item.cart_number) / Number(item.add_sl);

        if (num % 1 !== 0) {
          this.item.cart_number = Number(item.add_sl) * (parseInt(num) + 1);
        }
      }
      if (Number(item.cart_number) <= Number(item.step)) {
        this.item.cart_number = item.step;
      }
      if (Number(item.cart_number) >= Number(item.max_number)) {
        this.item.cart_number = item.max_number;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.goods_wrap {
  background-color: #fff;
  width: 230px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  margin-bottom: 12px;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 15px 0px rgba(221, 221, 221, 0.5);
  }
  .border-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 224px;
    height: 206px;
    border: 2px solid red;
    border-radius: 6px 6px 10px 10px;
    z-index: 99;
  }
  .border-box-yellow {
    position: absolute;
    top: 0;
    left: 0;
    width: 224px;
    height: 206px;
    border: 2px solid #f6d756;
    border-radius: 6px 6px 10px 10px;
    z-index: 99;
  }
  .img {
    margin: 0 auto;
    width: 200px;
    height: 200px;
    background: #eeeeee;
    border-radius: 4px;
    margin-bottom: 12px;
    position: relative;
    .cuxiao {
      position: absolute;
      left: -13px;
      bottom: 0;
      width: 227px;
      // width: 100%;
      padding: 3px 10px;
      box-sizing: border-box;
      background: #000000;
      background: red;
      // opacity: 0.65;
      font-size: 12px;
      color: #fff;
      color: #fcf4d9;
      overflow: auto;
      max-height: 100px;
      border-radius: 10px 10px 0 0;
      // border-radius: 10px;
    }
    .cuxiao-style {
      position: absolute;
      left: -13px;
      bottom: 0;
      width: 227px;
      height: 45px;
      background-image: url("../../assets/img/goods/hg01.png");
      border-radius: 0 0 10px 10px;
      overflow: hidden;
      .cx-box {
        width: 100%;
        height: 100%;
        position: relative;
        .cx-title {
          position: absolute;
          left: 12px;
          top: 4px;
          color: red;
          font-size: 13px;
        }
        .cx-price {
          position: absolute;
          top: 24px;
          left: 3px;
          width: 56px;
          text-align: center;
          font-size: 15px;
          font-weight: bold;
          color: #fcf4d9;
        }
        .cx-desc {
          position: absolute;
          top: 6px;
          left: 66px;
          // color: red;
          color: #fcf4d9;
          // color: #fff;
          overflow: hidden;
          // white-space: nowrap;
          -webkit-line-clamp: 2;
          // text-overflow: ellipsis;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-break: break-word;
          width: 156px;
          height: 45px;
          font-size: 13px;
        }
        .cx-num {
          position: absolute;
          width: 156px;
          top: 26px;
          left: 72px;
          color: #fcf4d9;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 13px;
        }
      }
    }
    .cuxiao-style-tj {
      position: absolute;
      left: -13px;
      bottom: 0;
      width: 227px;
      height: 45px;
      background-image: url("../../assets/img/goods/hg.png");
      border-radius: 0 0 10px 10px;
      overflow: hidden;
      .cx-box {
        width: 100%;
        height: 100%;
        position: relative;
        .cx-title {
          position: absolute;
          left: 12px;
          top: 4px;
          color: red;
          font-size: 13px;
        }
        .cx-price {
          position: absolute;
          top: 24px;
          left: 12px;
          width: 100px;
          // text-align: center;
          text-align: left;
          font-size: 15px;
          font-weight: bold;
          color: red;
        }
        .cx-desc {
          position: absolute;
          top: 20px;
          left: 80px;
          color: red;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 156px;
          font-size: 13px;
        }
        .cx-num {
          position: absolute;
          width: 156px;
          top: 26px;
          left: 72px;
          color: #fcf4d9;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 13px;
        }
      }
    }
  }
  .goods_con {
    width: 100%;
    box-sizing: border-box;
    padding: 0 5px;
    .price {
      font-size: 16px;
      font-weight: bold;
      color: @priceRed;
    }
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }

    .main {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.6;
      color: #666666;
    }
    .df_box {
      display: flex;
      justify-content: space-between;
    }
    .number_box {
      width: 91px;
      height: 26px;
      border: 1px solid #dddddd;
      border-radius: 2px;
      display: flex;
      .left {
        color: #ddd;
      }
      .left,
      .right {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .num {
        width: 36px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        ::v-deep .el-input__inner {
          padding: 0 4px;
          height: 26px;
          border: none;
          text-align: center;
        }
      }
    }
    .add {
      width: 80px;
      height: 25px;
      background: @themeColor;
      border-radius: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #fefefe;
      &.gray {
        background: #dddddd;
        color: #999;
      }
    }

    .tag_xie {
      top: 0;
      right: 0;
      position: absolute;
      width: 70px;
      height: 70px;
    }
    .sku {
      width: 100px;
      height: 92px;
      position: absolute;
      top: 65px;
      left: 65px;
    }
    .collect {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 10px;
      left: 10px;
    }
    .kegou {
      top: 0;
      left: 0;
      position: absolute;
      width: 110px;
      height: 30px;
      p {
        font-size: 12px;
        position: absolute;
        top: 6px;
        left: 10px;
      }
    }
  }
}
</style>
